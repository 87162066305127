<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">My Profile</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
        <div class="col-5">
          <h2 class="title2">My Profile</h2>
        </div>
        <div class="col text-end">
          <router-link
            :to="{ name: 'edit-admin-my-profile' }"
            class="btn btn-outline-primary"
            >Edit</router-link
          >
        </div>
      </div>
      <div v-if="this.$store.state.loader">
        <loader
          object="#f74b3d"
          color1="#ffffff"
          color2="#17fd3d"
          size="5"
          speed="2"
          bg="#343a40"
          objectbg="#999793"
          opacity="80"
          disableScrolling="false"
          name="spinning"
        ></loader>
      </div>
      <div v-else class="row mt-3">
        <div class="col-lg-4 mb-3">
          <div class="card">
            <div class="card-header">
              <div class="img-avatar">
                <img
                  class="avatar border-white"
                  :src="
                    myProfile.profile.avatar != ''
                      ? this.$baseUrl + '/faces/' + myProfile.profile.avatar
                      : asset('/images/faces/default_user.jpg')
                  "
                  alt="profile photo"
                />
              </div>
            </div>
            <div class="card-body">
              <table class="personal-tbl">
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>
                      <span class="p_value">
                        {{
                          myProfile.profile.firstname +
                          " " +
                          myProfile.profile.mi +
                          " " +
                          myProfile.profile.lastname
                        }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>
                      <span class="p_value">
                        {{ myProfile.profile.emailaddress }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Mobile no.</td>
                    <td>
                      <span class="p_value">{{
                        myProfile.profile.mobileno
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Employee Id</td>
                    <td>
                      <span class="p_value">
                        {{ myProfile.company_data.idno }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">
              <flash-message class="myCustomClass"></flash-message>
              <h2>Personal Information</h2>
            </div>
            <div class="card-body">
              <table class="personal-tbl">
                <tbody>
                  <tr>
                    <td><p>Civil Status</p></td>
                    <td>
                      <p>
                        {{
                          myProfile.profile.civilstatus != null
                            ? myProfile.profile.civilstatus
                            : "N/A"
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Age</p></td>
                    <td>
                      <p>
                        {{
                          myProfile.profile.age != null
                            ? myProfile.profile.age
                            : "N/A"
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Gender</p></td>
                    <td>
                      <p>
                        {{
                          myProfile.profile.gender != null
                            ? myProfile.profile.gender
                            : "N/A"
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Date of Birth</p></td>
                    <td>
                      <p>
                        {{
                          myProfile.profile.birthday != null
                            ? myProfile.profile.birthday
                            : "N/A"
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>PAN</p></td>
                    <td>
                      <p>
                        {{
                          myProfile.profile.pan != null &&
                          myProfile.profile.pan != ""
                            ? myProfile.profile.pan
                            : "N/A"
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Aadhar Number</p></td>
                    <td>
                      <p>
                        {{
                          myProfile.profile.aadhar != null &&
                          myProfile.profile.aadhar != ""
                            ? myProfile.profile.aadhar
                            : "N/A"
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Home Address</p></td>
                    <td>
                      <p>
                        {{
                          myProfile.profile.homeaddress != null &&
                          myProfile.profile.homeaddress != ""
                            ? myProfile.profile.homeaddress
                            : "N/A"
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <h4 class="ui">Designation</h4>
                    </td>
                  </tr>
                  <tr>
                    <td>Company</td>
                    <td>
                      {{
                        myProfile.company_data.company != null
                          ? myProfile.company_data.company
                          : "N/A"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td><p>Department</p></td>
                    <td>
                      <p>
                        {{
                          myProfile.company_data.department
                            ? myProfile.company_data.department
                            : "N/A"
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Position</td>
                    <td>
                      {{
                        myProfile.company_data.jobposition != null
                          ? myProfile.company_data.jobposition
                          : "N/A"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Leave Privilege</td>
                    <td>{{ leavePrivilege }}</td>
                  </tr>
                  <tr>
                    <td><p>Employment Type</p></td>
                    <td>
                      <p>{{ myProfile.profile.employmenttype }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Employement Status</p></td>
                    <td>
                      <p>{{ myProfile.profile.employmentstatus }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Official Start Date</p></td>
                    <td>
                      <p>{{ myProfile.company_data.startdate | formatDate }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>Date Regularized</p></td>
                    <td>
                      <p>
                        {{
                          myProfile.company_data.dateregularized | formatDate
                        }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    asset(path) {
      var base_path = window._asset || "";
      return base_path + path;
    },
    profileImagePath(path) {
      var base_path = window.baseUrl || "";
      return base_path + "/faces/" + path;
    },
  },
  computed: {
    myProfile() {
      return this.$store.state.myProfileData;
    },
    leavePrivilege() {
      if (
        this.$store.state.myProfileData.leavetype &&
        this.$store.state.myProfileData.leavegroup &&
        this.$store.state.myProfileData.company_data.leaveprivilege
      ) {
        let lp = this.$store.state.myProfileData.leavegroup.filter((lg) => {
          return (
            lg.id == this.$store.state.myProfileData.company_data.leaveprivilege
          );
        });
        let lpNames = "";
        lpNames += lp.map((item) => {
          return item.leavegroup;
        });
        return lpNames;
      } else {
        return "";
      }
    },
  },

  created() {
    var token = localStorage.getItem("token");
    if (token == "" || token == null) {
      window.location.href = "/login";
    }
    if (localStorage.getItem("my-profile-updated") == "true") {
      localStorage.removeItem("my-profile-updated");
      window.location.reload();
    }
  },
  mounted() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadMyProfile");
    this.$store.dispatch("loadUserStatus");
  },
};
</script>
